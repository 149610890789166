import React from 'react'
import PropTypes from 'prop-types'
import { ContainerLogo } from './style'
import { motion } from 'framer-motion'

const Logo = ({ logo, name }) => {
  return (
        <ContainerLogo className="logo tw-flex tw-items-center tw-justify-center">
            <motion.img src={logo} atl={name} className="tw-max-h-full"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 2 }}
            />
        </ContainerLogo>
  )
}

Logo.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string
}

export default Logo
