import styled from 'styled-components'
import { SIDEBAR_CONTENT, SIDEBAR_SMALL } from './constants'

export const SidebarSmall = styled.div`
  width: ${SIDEBAR_SMALL}px;
`

export const ContentSidebarContent = styled.div`
  width: ${SIDEBAR_CONTENT}px;
`
