import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './infrastructure/styles/index.css'
import './infrastructure/localization/i18n'
const styleArray = [
  'background-color: white',
  'color: red',
  'border: 2px solid red',
  'font-size: 16px',
  'padding: 0px 15px 15px'
].join(';')
// eslint-disable-next-line no-console
console.log('%cEsta función del navegador está pensada para desarrolladores.', styleArray)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
