import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../Logo/Logo'
import { Container } from './styles'
import { useLogoDinamyc } from './hooks/useLogoDynamic'
const LogoDynamic = ({ logo, software, mode, link }) => {
  const { getSoftwareName, getClassDynamicText } = useLogoDinamyc(mode)
  const onLink = () => {
    if (link) { window.open(link) }
  }
  return (
    <Container onClick={onLink} className="LogoDynamic tw-flex tw-h-full tw-cursor-pointer">
      <Logo src={logo} mode={mode} />
      <div className={`LogoDynamic-software ${getClassDynamicText()} `}>
        {getSoftwareName(software)}
      </div>
    </Container>
  )
}

LogoDynamic.propTypes = {
  logo: PropTypes.string,
  software: PropTypes.string,
  version: PropTypes.string,
  mode: PropTypes.oneOf(['white', 'default']),
  link: PropTypes.string
}

LogoDynamic.defaultProps = {
  version: '1.0.0',
  mode: 'white'
}

export default LogoDynamic
