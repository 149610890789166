import { SET_COLORS, SET_COLORS_FAILED, SET_COLORS_SUCCESS, SET_THEME } from '../constants/colors'

const initialState = {
  color: null,
  loading: false,
  theme: null
}
const colorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COLORS:
      return {
        ...state,
        loading: true
      }
    case SET_COLORS_SUCCESS:
      return {
        ...state,
        loading: false,
        colors: action.data
      }
    case SET_COLORS_FAILED:
      return {
        ...state,
        loading: false
      }

    case SET_THEME:
      return {
        ...state,
        theme: action.data
      }
    default:
      return state
  }
}

export default colorsReducer
