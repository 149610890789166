import React, { useEffect, useState } from 'react'
import useLogin from '../../hooks/useLogin'
import { useHistory, useParams } from 'react-router'
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'

const AuthScreen = () => {
  /// custom hooks
  const { onAutologin } = useLogin()
  const history = useHistory()
  const { token } = useParams()
  const [text, setText] = useState('Autorizando')

  /**
   * form submit for login form
   *
   *
   * @author   ehernandez
   * @param    {Object} values element
   */
  const onSubmit = async (values) => {
    const { success, errors } = await onAutologin(values)
    console.log(success, errors)
    if (!success) {
      setText('Usuario no identificado')
      return {
        success,
        errors
      }
    }
    history.push('/')
  }

  useEffect(() => {
    if (token) {
      onSubmit(token)
    }
  }, [])

  return <div className="tw-h-screen tw-w-screen tw-flex tw-items-center tw-justify-center">
    <Paragraphs>{text}</Paragraphs>
  </div>
}

export default AuthScreen
