/* eslint-disable */
import { createGlobalStyle } from 'styled-components'

/**
   * Transform color from hexadecimal to rgba
   *
   *
   * @author   ehernandez
   * @param    {String}   hex  color hexadecimal
   * @param    {Boolean}  type  functionalidad tailwind to aplicate color rgba
   */
function hexToRgbA(hex, type = 'bg') {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = `0x${c.join('')}`
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ',',
    )}, var(--tw-${type}-opacity))`
  }
  return hex
}

const GlobalStyle = createGlobalStyle`
.tw-bg-primary{
    background: ${({ paleta }) => hexToRgbA(paleta?.color_primario)};
}

.hover\\:tw-bg-primary:hover{
  background: ${({ paleta }) => hexToRgbA(paleta?.color_primario)};
}
.checked\\:tw-bg-primary:checked{
  background: ${({ paleta }) => hexToRgbA(paleta?.color_primario)};
  border-color: ${({ paleta }) => hexToRgbA(paleta?.color_primario, 'border')};
}

.tw-border-primary{
  border-color: ${({ paleta }) => hexToRgbA(paleta?.color_primario, 'border')};
}

.tw-text-primary{
  color: ${({ paleta }) => hexToRgbA(paleta?.color_primario, 'text')};
}
.tw-bg-secondary{
    background: ${({ paleta }) => hexToRgbA(paleta?.color_secundario)};
}

.tw-border-secondary{
  border-color: ${({ paleta }) => hexToRgbA(paleta.color_secundario, 'border')};
}

.tw-text-secondary{
  color: ${({ paleta }) => paleta?.color_secundario};
}
.tw-border-tertiary{
  border-color: ${({ paleta }) => paleta?.color_terciario};
}
.tw-bg-tertiary{
    background: ${({ paleta }) => paleta?.color_terciario};
}
.tw-text-tertiary{
  color: ${({ paleta }) => paleta?.color_terciario};
}

`

export {  GlobalStyle }
