import { useState } from 'react'
import useMutation from '../../../../infrastructure/hooks/useMutation'
import { AUTOLOGIN, LOGIN } from '../../api'
import { useDispatch } from 'react-redux'
import { actionSetTokenUser } from '../../../../infrastructure/redux/actions/user'

export const useLogin = () => {
  const [handleLogin] = useMutation(LOGIN, false)
  const [handleAutologin] = useMutation(AUTOLOGIN)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  /**
   * when excute function logi
   *
   *
   * @author   ehernandez
   * @param    {Object} data element
   * @param    {String}   data.username
   * @param    {String}   data.password
   */
  const onLogin = async (values) => {
    setLoading(true)
    const { success, data, errors } = await handleLogin({ method: 'post', data: values })
    setLoading(false)
    if (success) {
      // save data into redux and local storage
      dispatch(actionSetTokenUser(data))
      return {
        success,
        data
      }
    }

    return {
      success,
      errors
    }
  }
  /**
   * when excute function logi
   *
   *
   * @author   ehernandez
   * @param    {String} token element
   */
  const onAutologin = async (token) => {
    setLoading(true)
    const { success, data, errors } = await handleAutologin({ method: 'get', addToURL: `/${token}` })
    console.log(data)
    setLoading(false)
    if (success) {
      // save data into redux and local storage
      dispatch(actionSetTokenUser(data))
      return {
        success,
        data
      }
    }

    return {
      success,
      errors
    }
  }

  return { onLogin, loading, onAutologin }
}
