import {
  // BannerCloud,
  Paragraphs
} from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useInfoCorporate } from '../../../../infrastructure/hooks/useInfoCorporate/useInfoCorporate'
import { useUser } from '../../../../infrastructure/hooks/useUser/useUser'
import ItemSoftwares from '../../components/ItemSoftware/ItemSoftwares'

const HomeScreen = () => {
  const { getApps, apps } = useInfoCorporate()
  const { t, i18n } = useTranslation()
  const { token, idioma } = useSelector((state) => state.userReducer)

  const { handleUser } = useUser()

  useEffect(() => {
    if (token) {
      getApps()
      handleUser()
    }

    i18n.changeLanguage(idioma)
  }, [])

  /**
   * open software on new chrome tab
   *
   *
   * @author   ehernandez
   * @param   {Object} app
   * @param   {Object}  app.url
   * @param   {Object}  app.comprado
   */

  const openSotware = (app) => {
    if (app?.comprada) {
      const token = app.token || ''
      window.open(app.url + token)
    }
  }

  const getCol = (a, index) => {
    if (a.length === index + 1 && index % 2 === 0) {
      return 'full'
    }
    return '6/12'
  }
  const compradas = apps.filter(i => i.comprada)
  // const compradas = apps.filter(i => i.comprada)
  return (
    <div className="home-screen tw-h-full tw-flex tw-flex-col sm:tw-flex-row ">
      {/* <MenuSoftwares /> */}
      <div className="tw-overflow-auto tw-flex-1 tw-p-4">
      <Paragraphs weight='bold' className="tw-px-2" uppercase>{t('menu.Tus aplicaciones')}</Paragraphs>
        <div className="home-screen-softwares tw-flex tw-flex-wrap">
          {compradas.map((i, index) => (
            <motion.div
              onClick={() => openSotware(i)}
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ transition: 1.2, delay: index * 0.2 }}
              key={i.id}
              // style={{ height: 300 }}
              className={`tw-w-full tw-my-3 tw-px-2 tw-w-full md:tw-w-${getCol(compradas, index)}`}
            >
              <ItemSoftwares
                image={i.logo}
                name={i.nombre}
                notifications={true}
                description={i.descripcion}
                btnText='Abrir'
              />
            </motion.div>
          ))}
        </div>
        {/* <div className="tw-py-8">
        <Paragraphs weight='bold' className="tw-px-2" uppercase>{t('menu.También te puede interesar')}</Paragraphs>
        <div className="home-screen-softwares tw-flex tw-flex-wrap">
          {apps.map((i, index) => !i.comprada && (
            <motion.div
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ transition: 1.2, delay: index * 0.2 }}
              key={i.id}
              className={'tw-w-full tw-my-3 tw-px-2 tw-w-full md:tw-w-4/12 '}
            >
              <ItemSoftwares
                image={i.logo}
                name={i.name}
                notifications={true}
                description={i.description}
                btnText='Ver más'
              />
            </motion.div>
          ))}
        </div>
        </div> */}
      </div>
    </div>
  )
}

export default HomeScreen
