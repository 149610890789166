import React from 'react';
import PropTypes from 'prop-types';
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git';
import IcFolder from '../../../../../../../Icons/IcFolder';
import ItemFavorito from './ItemFavorito/ItemFavorito';
import { ContainerFolder } from './styles';
import { useDrop } from 'react-dnd';

const Folder = ({ descripcion, items, visible, id, onMoveFav }) => {
	const [{ isOver }, drop] = useDrop({
		accept: 'MENU',
		drop: () => ({
			id,
		}),
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	});

	return (
		<div
			ref={drop}
			className='Folder'
			style={{
				backgroundColor: isOver ? 'rgba(00,0,0,.2)' : 'transparent',
			}}
		>
			<ContainerFolder
				hasChildren={items.length > 0}
				className='tw-flex t-items-center tw-py-2 tw-px-2'
			>
				{visible && (
					<>
						<IcFolder color='white' size={20} />
						<Paragraphs
							className='tw-px-3 tw-text-white '
							weight='bold'
							size='base'
						>
							{descripcion}
						</Paragraphs>
					</>
				)}
			</ContainerFolder>
			<div className='folder-inner-items tw-pl-7'>
				{items.map((i) => {
					return (
						<ItemFavorito
							onMoveFav={onMoveFav}
							key={i.id}
							idFav={i.id}
							nombre={i.recurso.nombre}
							id={i.recurso?.id}
							software={i.recurso?.software?.nombre}
						/>
					);
				})}
			</div>
		</div>
	);
};

Folder.propTypes = {
	items: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		recurso: PropTypes.object,
		length: PropTypes.number,
		map: PropTypes.func,
	}),
	descripcion: PropTypes.string,
	visible: PropTypes.bool,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onMoveFav: PropTypes.func,
};
Folder.defaultProps = {
	items: [],
	visible: true,
};
export default Folder;

