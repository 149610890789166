import React from 'react'
import PropTypes from 'prop-types'
import SidebarHeader from '../SidebarHeader/SidebarHeader'
import Module from './components/Module/Module'
import SearchComponent from './components/SearchComponent'
import { ConainerModule } from './styles'
import Favorites from './components/Favorites/Favorites'

const SidebarContent = ({
  user,
  warehouse,
  pharmacy,
  software,
  module,
  onClickItemMenu,
  onClickFav,
  type,
  onMoveFav,
  onSearch,
  modeTextLink
}) => {
  const selectedTypeModule = () => {
    switch (type) {
      case 'favorites':
        return (
          <ConainerModule className="tw-flex-1">
            <Favorites
              items={module?.children}
              onClickItemMenu={onClickItemMenu}
              onClickFav={onClickFav}
              onMoveFav={onMoveFav}
            />
          </ConainerModule>
        )

      default:
        return (
          <>
            <ConainerModule className="tw-flex-1">
              <Module
                title={module?.nombre}
                items={module?.children}
                onClickItemMenu={onClickItemMenu}
                onClickFav={onClickFav}
                modeTextLink={modeTextLink}
              />
            </ConainerModule>
           {onSearch && <SearchComponent onChangeText={onSearch} />}
          </>
        )
    }
  }

  return (
    <div className="SidebarContent tw-bg-secondary tw-h-screen tw-w-full tw-flex tw-flex-col tw-justify-between ">
      <SidebarHeader
        software={software}
        user={user}
        warehouse={warehouse}
        pharmacyName={pharmacy?.nombre}
        logo={pharmacy?.logo}
      />
      {selectedTypeModule()}
    </div>
  )
}

SidebarContent.propTypes = {
  pharmacy: PropTypes.shape({
    logo: PropTypes.string,
    nombre: PropTypes.string
  }),
  warehouse: PropTypes.string,
  user: PropTypes.shape({
    nickname: PropTypes.string
  }),
  software: PropTypes.string,
  module: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.object),
    nombre: PropTypes.string
  }),
  onClickFav: PropTypes.func,
  onClickItemMenu: PropTypes.func,
  type: PropTypes.oneOf(['default', 'favorites', 'configurations']),
  onMoveFav: PropTypes.func,
  onSearch: PropTypes.func,
  modeTextLink: PropTypes.string
}

SidebarContent.defaultProps = {
  software: '',
  pharmacy: {},
  warehouse: '',
  user: {}

}

export default SidebarContent
