import styled from 'styled-components'

export const ContainerSearchComponent = styled.div`
  height: 56px;
`
export const InputSearch = styled.input`
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  font-weight: "bold";
  font-size: 14px;
  &::placeholder {
    font-weight: "bold";
    color: white;
    opacity: 0.7; /* Firefox */
    font-style: italic;
  }

  &:focus {
    outline: none;
  }
`
