import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'

const IcFavorite = ({ size, color }) => {
  return (
    <Icon
      className="IcFavorite material-icons tw-items-center tw-justify-center"
      size={size}
      color={color}
    >
      favorite
    </Icon>
  )
}

IcFavorite.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
}

export default IcFavorite
