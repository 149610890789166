import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ContentSidebarContent, SidebarSmall } from './styles';
import BtnSoftwares from '../BtnSoftwares';
import Softwares from './components/Softwares';
import SidebarContent from './components/SidebarContent';
import BtnBack from './components/BtnBack';

const Sidebar = ({
	softwares,
	onSelectSoftware,
	itemsConfigurations,
	data,
	user,
	warehouse,
	pharmacy,
	onCloseMenu,
	favorites,
	position,
	onClickItemMenu,
	onClickAddFav,
	onSearch,
	modeTextLink,
}) => {
	const [open, setOpen] = useState(false);
	const [moduleSelected, setModuleSelected] = useState(null);
	const [openSoftwares, setOpenSoftwares] = useState(false);
	const handleOpenSoftwares = () => setOpenSoftwares(true);
	const closeSidebarSoftwares = () => setOpenSoftwares(false);

	const handleClickOnSoftware = (software) => {
		closeSidebarSoftwares();
		if (onSelectSoftware) {
			onSelectSoftware(software);
		}
	};

	const getSelectMenu = () => {
		switch (moduleSelected.type) {
			case 'configurations':
				return itemsConfigurations.children.find(
					(i) => i.id === moduleSelected.id,
				);

			case 'favorites':
				return {
					title: 'Favoritos',
					children: favorites,
				};
			default:
				return data && data.find((i) => i.id === moduleSelected.id);
		}
	};

	const handleClickOnMenu = (item) => {
		if (onClickItemMenu) {
			onClickItemMenu(item);
		}
		toogleMenu();
	};

	const toogleMenu = () => {
		setOpen(false);
		setModuleSelected(null);
		if (onCloseMenu) onCloseMenu();
	};
	useEffect(() => {
		if (moduleSelected?.id || moduleSelected?.type) {
			setOpen(true);
		}
	}, [moduleSelected]);

	return (
		<div
			className={`Sidebar tw-top-0 tw-h-full tw-left-0 tw-flex tw-${position}`}
			style={{ zIndex: 99 }}
		>
			<SidebarSmall className='Sidebar-small tw-bg-primary tw-h-full tw-flex tw-flex-col tw-justify-between tw-relative tw-z-20'>
				<div>
					{open ? (
						<BtnBack onClick={toogleMenu} />
					) : (
						softwares && (
							<BtnSoftwares open={open} onClick={handleOpenSoftwares} />
						)
					)}

					{openSoftwares && (
						<Softwares
							items={softwares}
							onRequestClose={closeSidebarSoftwares}
							onClickSoftware={handleClickOnSoftware}
						/>
					)}
				</div>
			</SidebarSmall>
			{open && (
				<>
					<div
						onClick={toogleMenu}
						className='tw-absolute tw-left-0 tw-top-0 tw-h-screen tw-w-screen tw-bg-black tw-bg-opacity-30 tw-z-10 tw-cursor-pointer'
					/>
					<ContentSidebarContent className='tw-bg-secondary tw-h-full tw-relative tw-z-20'>
						<SidebarContent
							onSearch={onSearch}
							user={user}
							pharmacy={pharmacy}
							type={moduleSelected.type}
							software={data && data?.nombre}
							module={getSelectMenu()}
							warehouse={warehouse.nombre}
							onClickItemMenu={handleClickOnMenu}
							onClickFav={onClickAddFav}
							modeTextLink={modeTextLink}
						/>
					</ContentSidebarContent>
				</>
			)}
		</div>
	);
};

Sidebar.propTypes = {
	open: PropTypes.bool,
	softwares: PropTypes.arrayOf(
		PropTypes.shape({
			nombre: PropTypes.string,
			id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			children: PropTypes.arrayOf(
				PropTypes.shape({
					nombre: PropTypes.string,
					id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
					children: PropTypes.arrayOf(PropTypes.object),
					component: PropTypes.string,
					software_id: PropTypes.oneOfType([
						PropTypes.number,
						PropTypes.string,
					]),
				}),
			),
		}),
	),
	onSelectSoftware: PropTypes.func,
	itemsConfigurations: PropTypes.shape({
		children: PropTypes.arrayOf(
			PropTypes.shape({
				children: PropTypes.arrayOf(PropTypes.object),
				id: PropTypes.string,
			}),
		),
	}),
	data: PropTypes.array,
	user: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		nickname: PropTypes.string,
	}),
	warehouse: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		nombre: PropTypes.string,
	}),
	pharmacy: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		nombre: PropTypes.string,
		logo: PropTypes.string,
	}),
	onCloseMenu: PropTypes.func,
	favorites: PropTypes.arrayOf(PropTypes.object),
	position: PropTypes.oneOf(['absolute', 'fixed', 'relative']),
	onClickItemMenu: PropTypes.func,
	onClickAddFav: PropTypes.func,
	onSearch: PropTypes.func,
	modeTextLink: PropTypes.string,
};

Sidebar.defaultProps = {
	open: false,
	onSelectSoftware: null,
	itemsConfigurations: {},
	data: null,
	user: {},
	warehouse: {},
	onCloseMenu: () => null,
	position: 'relative',
};

export default Sidebar;
