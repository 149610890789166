import React from 'react';
import PropTypes from 'prop-types';
import { ContainerSidebarHeader } from './styles';
import LogoDynamic from '../../../LogoDynamic';
// import Paragraphs from '../../../Paragraphs'

const SidebarHeader = ({ software, logo, user, pharmacyName, warehouse }) => {
	return (
		<ContainerSidebarHeader className='SidebarHeader tw-bg-white tw-overflow-hidden tw-p-3 tw-flex'>
			<LogoDynamic logo={logo} mode='default' software={software} />
		</ContainerSidebarHeader>
	);
};

SidebarHeader.propTypes = {
	software: PropTypes.string,
	logo: PropTypes.string,
	pharmacyName: PropTypes.string,
	warehouse: PropTypes.string,
	user: PropTypes.shape({
		nickname: PropTypes.string,
	}),
};

SidebarHeader.defaultProps = {
	pharmacyName: '',
	warehouse: '',
};

export default SidebarHeader;
