import React from 'react';
import PropTypes from 'prop-types';
import { ContainerItemMenu, ContentFav, ContainerSubItemMenu } from './styles';
import BtnFavorite from './components/BtnFavorite/BtnFavorite';
import IcArrow from '../../../../../Icons/IcArrow/IcArrow';
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git';

const ItemMenu = ({
	nombre,
	onClickFav,
	idFav,
	childrens,
	mode,
	id,
	onClickItemMenu,
	software,
}) => {
	return (
		<ContainerItemMenu>
			{software && (
				<Paragraphs
					className='tw-text-white tw-text-opacity-70 tw-border-b tw-border-white tw-py-1 tw-mx-3'
					weight='bold'
				>
					{software}
				</Paragraphs>
			)}
			<div className='ItemMenu tw-flex tw-justify-between tw-relative tw-cursor-pointer'>
				<div
					className='tw-flex-1 tw-py-4 tw-px-3 tw-relative tw-z-10 tw-relative'
					onClick={() => onClickItemMenu(id)}
				>
					<Paragraphs className={`tw-text-${mode}`} size='xs'>
						{nombre}
					</Paragraphs>
				</div>
				{childrens && childrens.length > 0 ? (
					<div className={`tw-py-4 tw-text-${mode} tw-px-3`}>
						<IcArrow size={16} />
					</div>
				) : (
					onClickFav && (
						<ContentFav
							className='tw-z-10 tw-flex tw-items-center tw-justify-center'
							onClick={() => onClickFav(idFav)}
						>
							<BtnFavorite id={idFav} mode={mode} />
						</ContentFav>
					)
				)}

				<ContainerSubItemMenu
					id={id}
					className={'SubMenuItem tw-absolute tw-top-0'}
				>
					{childrens &&
						childrens.length > 0 &&
						childrens.map((i) => (
							<ItemMenu
								mode='black'
								key={i.id}
								nombre={i.nombre}
								childrens={i.children}
								onClickFav={() => onClickFav(i.id)}
								onClickItemMenu={onClickItemMenu}
							/>
						))}
				</ContainerSubItemMenu>
			</div>
		</ContainerItemMenu>
	);
};

ItemMenu.propTypes = {
	nombre: PropTypes.string,
	onClickFav: PropTypes.func,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	idFav: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	childrens: PropTypes.arrayOf(
		PropTypes.shape({
			nombre: PropTypes.string,
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	),
	mode: PropTypes.oneOf(['black', 'white']),
	onClickItemMenu: PropTypes.func,
	software: PropTypes.string,
};

ItemMenu.defaultProps = {
	mode: 'white',
	childrens: [],
	onClickItemMenu: () => null,
};

export default ItemMenu;

