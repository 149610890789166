import AuthScreen from '../../modules/Auth/pages/AuthScreen'
import HomeScreen from '../../modules/Home/pages/HomeScreen'
import LoginScreen from '../../modules/Login/pages/LoginScreen/LoginScreen'
import Layout from '../components/Layout'

const authProtectedRoutes = [
  { path: '/', component: HomeScreen, id: 2, layout: Layout }
]

const publicRoutes = [
  { path: '/auth/:token', component: AuthScreen, id: 1 },
  { path: '/login', component: LoginScreen, id: 0 }

]

export { authProtectedRoutes, publicRoutes }
