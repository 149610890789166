import { SET_COLORS, SET_COLORS_FAILED, SET_COLORS_SUCCESS, SET_THEME } from '../constants/colors'

export const actionSetColors = () => ({
  type: SET_COLORS
})

export const actionSetColorsSuccess = (data) => ({
  type: SET_COLORS_SUCCESS,
  data
})
export const actionSetColorsFailed = () => ({
  type: SET_COLORS_FAILED
})

export const actionSetTheme = (data) => ({
  type: SET_THEME,
  data
})
