import React from 'react';
import PropTypes from 'prop-types';
import { ContainerItemSoftware } from './styles';
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git';

const ItemSoftware = ({ name, onClick }) => {
	const spliName = () => {
		if (name) {
			const splited = name.split(' ');
			if (splited.length === 2) {
				return splited.map((i, idx) => {
					const color = idx ? 'primary' : 'secondary';
					return (
						<Paragraphs key={idx} className={`tw-text-${color}`} size='lg'>
							{i}
						</Paragraphs>
					);
				});
			} else {
				return (
					<>
						<Paragraphs className='tw-text-secondary' size='lg'>
							{splited[0]}
						</Paragraphs>
						<Paragraphs className='tw-text-primary' size='lg'>
							{splited.map((i, idx) => (idx ? i : '')).join(' ')}
						</Paragraphs>
					</>
				);
			}
		}

		return null;
	};
	return (
		<ContainerItemSoftware
			onClick={onClick}
			className='group ItemSoftware tw-border tw-border-gray-200 tw-px-6 tw-py-3 tw-flex tw-items-center tw-cursor-pointer hover:tw-bg-gray-100 tw-my-4'
		>
			<div className='ItemSoftware tw-border-l-2 tw-border-gray-200 tw-pl-3'>
				{spliName()}
			</div>
		</ContainerItemSoftware>
	);
};

ItemSoftware.propTypes = {
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func,
};

export default ItemSoftware;

