import React from 'react'
import PropTypes from 'prop-types'
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'

const ICloudButton = ({ label, background, rounded, border, uppercase, fontSize, color, onClick }) => {
  const getClasses = () => {
    let styles = 'tw-px-6 tw-py-2 tw-flex tw-items-center tw-justify-center'
    if (rounded) styles += ' tw-rounded-full '
    if (border) styles += ' tw-border-2 tw-border-' + border
    if (uppercase) styles += ' tw-uppercase '

    styles += ' tw-text-' + color
    styles += ' tw-bg-' + background

    return styles
  }
  return (
        <div onClick={onClick} className={`i-cloud-button tw-cursor-pointer ${getClasses()}`}>
            <Paragraphs size={fontSize}>{label}</Paragraphs>
        </div>
  )
}

ICloudButton.propTypes = {
  border: PropTypes.oneOf(['black', 'white', 'primary', 'secondary']),
  color: PropTypes.oneOf(['black', 'white', 'primary', 'secondary']),
  background: PropTypes.oneOf(['transparent', 'white', 'primary', 'secondary']),
  fontSize: PropTypes.oneOf(['xs', 'sm', 'base', 'lg']),
  rounded: PropTypes.bool,
  label: PropTypes.string,
  uppercase: PropTypes.bool,
  onClick: PropTypes.func

}

ICloudButton.defaultProps = {
  fontSize: 'xs',
  background: 'transparent',
  color: 'black'
}

export default ICloudButton
