export const sociales = {
  xarxafarma: [
    {
      id: 1,
      tag: 'facebook',
      img: require('../assets/xarxafarma/sociales/xarxa-facebook.png'),
      link: 'https://www.facebook.com/XarxaFarmaOficial'
    },
    {
      id: 2,
      tag: 'twitter',
      img: require('../assets/xarxafarma/sociales/xarxa-twiter.png'),
      link: 'https://twitter.com/xarxafarma'
    },
    {
      id: 3,
      tag: 'instagram',
      img: require('../assets/xarxafarma/sociales/xarxa-instagram.png'),
      link: 'https://www.instagram.com/xarxafarma'
    },
    {
      id: 4,
      tag: 'linkedin',
      img: require('../assets/xarxafarma/sociales/xarxa-linkedin.png'),
      link: 'https://www.linkedin.com/company/xarxafarma'
    },
    {
      id: 5,
      tag: 'youtube',
      img: require('../assets/xarxafarma/sociales/xarxa-yout.png'),
      link: 'https://www.youtube.com/channel/UCAoPJMCuczQf3rTlUHZYlzQ'
    }
  ]
}
