import { SET_APPS, SET_INFO_CORPORATE } from '../constants/corporate'

export const actionSetCorporateInfo = (data) => ({
  type: SET_INFO_CORPORATE,
  data
})

export const actionSetApp = (data) => ({
  type: SET_APPS,
  data
})
