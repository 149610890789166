import React from 'react'
import LogoWhite from '../../../../assets/xf_white.png'
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import { sociales } from '../../../../constants/social'

const Footer = () => {
  return (
    <div className="xarxafamra-login-footer tw-bg-primary tw-pb-6 ">
      <div className="tw-flex tw-flex-wrap tw-px-4 tw-pb-4 tw-pt-10">
        <div className="xarxafarma-login-footer tw-w-full sm:tw-w-3/12 tw-flex tw-items-center tw-justify-center tw-py-3 ">
          <img src={LogoWhite} alt="xarxafarma" style={{ maxHeight: 50 }} />
        </div>
        <div className="tw-flex-1 tw-flex-wrap tw-flex tw-items-center tw-justify-center tw-flex-col tw-py-3 ">
          <div className="tw-flex">
            <Paragraphs size="xl" className="tw-text-white tw-cursor-pointer">
              xarxafarma.com
            </Paragraphs>
            <div className="tw-border-l tw-border-white tw-mx-3" />
            <Paragraphs size="xl" className="tw-text-white tw-cursor-pointer">
              neoxfarma.com
            </Paragraphs>
          </div>
        </div>
        <div className="tw-w-full sm:tw-w-3/12  tw-flex tw-justify-center tw-py-3 ">
          {sociales.xarxafarma.map((i) => {
            return (
              <div onClick={() => window.open(i.link)} key={i.id} className="tw-h-6 tw-w-6 tw-mx-3">
                <img src={i.img} alt={i.tag} />
              </div>
            )
          })}
        </div>
      </div>
      <div className="tw-flex tw-justify-center">
        <a
          className="tw-flex tw-justify-center"
          href="https://apps.xarxafarma.com/cookies"
        >
          <Paragraphs
            className="tw-text-white tw-mt-6 tw-cursor-pointer"
            uppercase
          >
            Politica de cookies
          </Paragraphs>
        </a>
      </div>
    </div>
  )
}

export default Footer
