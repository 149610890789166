import React from 'react';
import PropTypes from 'prop-types';
import { ContainerSearchComponent, InputSearch } from './styles';
import IcSearch from '../../../../../Icons/IcSearch';

const SidebarSearchComponent = ({ value, onChangeText, placeholder }) => {
	return (
		<ContainerSearchComponent className='SidebarSearchComponent tw-relative'>
			<InputSearch
				type='search'
				name='search'
				className='tw-h-full tw-w-full tw-pl-4 tw-pr-5'
				placeholder={placeholder}
				value={value}
				onChange={(e) => onChangeText(e.target.value)}
			/>
			<div className='tw-absolute tw-right-5 tw-top-4'>
				{value === '' && <IcSearch size={24} color='white' />}
			</div>
		</ContainerSearchComponent>
	);
};

SidebarSearchComponent.propTypes = {
	value: PropTypes.string,
	onChangeText: PropTypes.func,
	placeholder: PropTypes.string,
};

SidebarSearchComponent.defaultProps = {
	placeholder: '',
	value: '',
};

export default SidebarSearchComponent;

