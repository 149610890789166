import React from 'react';
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git';
export const useLogoDinamyc = (mode) => {
	const color = mode === 'default' ? 'primary' : mode;

	/**
	 * Separa en dos lineas el nombre del software
	 * @author   ehernandez
	 * @param    {name} String
	 */
	const getSoftwareName = (name) => {
		const words = name ? name.split(' ') : null;
		if (words) {
			return words.map((i) => (
				<Paragraphs
					className={`tw-text-${color} tw-capitalize`}
					key={i}
					size='xxs'
				>
					{i}
				</Paragraphs>
			));
		}

		return null;
	};

	/**
	 * Obtienen clases para los textos
	 * @author   ehernandez
	 * @return    {String}
	 */
	const getClassDynamicText = () => {
		return `tw-ml-2 tw-pl-2 tw-border-l tw-border-${color}`;
	};

	return { getSoftwareName, getClassDynamicText };
};

