import React from 'react';
import PropTypes from 'prop-types';
import { BtnCloseSoftwares, ContainerSoftwares } from './styles';
import IcClose from '../../../Icons/IcClose';
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git';
import ItemSoftware from './components/ItemSoftware';

const Softwares = ({ items, onClickSoftware, onRequestClose }) => {
	return (
		<div className='Softwares tw-left-0 tw-top-0 tw-fixed tw-z-50 tw-h-full tw-w-screen tw-bg-black tw-bg-opacity-40'>
			<ContainerSoftwares className='tw-bg-white tw-h-screen tw-relative tw-overflow-auto'>
				<BtnCloseSoftwares
					onClick={onRequestClose}
					className='btnClose tw-text-primary tw-p-4 tw-flex tw-justify-end tw-cursor-pointer'
				>
					<IcClose size={25} />
				</BtnCloseSoftwares>
				<div className='SidebarSoftwares-Content tw-px-4'>
					<Paragraphs>Aplicaciones</Paragraphs>
					{items.map((i) => (
						<ItemSoftware
							onClick={() => onClickSoftware(i)}
							key={i.id}
							id={i.id}
							name={i.nombre}
						/>
					))}
				</div>
			</ContainerSoftwares>
		</div>
	);
};

Softwares.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			nombre: PropTypes.string,
			id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		}),
	),
	onClickSoftware: PropTypes.func,
	onRequestClose: PropTypes.func.isRequired,
};

Softwares.defaultProps = {
	items: [],
	onClickSoftware: () => null,
};

export default Softwares;
