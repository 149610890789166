export const forms = {
  login: [
    {
      id: 1,
      label: 'Usuario',
      name: 'username',
      type: 'text'
    },
    {
      id: 2,
      label: 'Password',
      name: 'password',
      type: 'password'
    }
  ]
}
