import React from 'react'
import PropTypes from 'prop-types'
import { useDrag } from 'react-dnd'
import ItemMenu from '../../../../ItemMenu'

const ItemFavorito = ({
  id,
  software,
  nombre,
  idFav,
  onClickItemMenu,
  onClickFav,
  onMoveFav
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'MENU',
    item: {
      id,
      idFav,
      nombre
    },
    end (itemA, monitor) {
      const dropResult = monitor.getDropResult()
      if (itemA && dropResult) {
        onMoveFav({ idFolder: dropResult.id, idFav: idFav, idItem: id })
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })
  return (
    <div
      ref={drag}
      className="ItemFavorito"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <ItemMenu
        nombre={nombre}
        idFav={idFav}
        onClickItemMenu={onClickItemMenu}
        onClickFav={onClickFav}
        software={software}
      />
    </div>
  )
}

ItemFavorito.propTypes = {
  nombre: PropTypes.string,
  onClickFav: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  idFav: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClickItemMenu: PropTypes.func,
  software: PropTypes.string,
  onMoveFav: PropTypes.func
}

export default ItemFavorito
