import React from 'react'
import PropTypes from 'prop-types'
import { ContainerBtnSoftwares } from './styles'
import IcArrow from '../../../Icons/IcArrow/IcArrow'

const BtnBack = ({ onClick }) => {
  return (
    <ContainerBtnSoftwares
      onClick={onClick}
      className="BtnSoftwares tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
    >
      <IcArrow color="white" size={30} direction={'left'} />
    </ContainerBtnSoftwares>
  )
}

BtnBack.propTypes = {
  onClick: PropTypes.func,
  open: PropTypes.bool
}

BtnBack.defaultProps = {
  open: true
}

export default BtnBack
