import React from 'react'
import PropTypes from 'prop-types'
import IcGrid from '../Icons/IcGrid'
import { ContainerBtnSoftwares } from './styles'

const BtnSoftwares = ({ color, open, onClick }) => {
  return (
    <ContainerBtnSoftwares
      onClick={onClick}
      className="BtnSoftwares tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
    >
      <IcGrid color={color} size={30} />
    </ContainerBtnSoftwares>
  )
}

BtnSoftwares.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  open: PropTypes.bool
}

BtnSoftwares.defaultProps = {
  color: 'white',
  open: true
}

export default BtnSoftwares
