import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import {
  Button,
  Icons,
  // Icons,
  Paragraphs,
  TextInput
} from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import { forms } from '../../../../constants/forms'
import { useForm } from 'react-hook-form'
import Footer from '../Footer'

const Login = ({ colors, onSubmit, name }) => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm()
  return (
    <div className="xarxafarma-login tw-h-screen  tw-flex tw-justify-between tw-flex-col tw-pt-20">
      <motion.div
        initial={{ opacity: 0, y: '-100%' }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ transition: 1.2 }}
        className="header-xarxafarma-login tw-h-16 tw-w-full tw-bg-gray-200 tw-shadow-md tw-fixed tw-top-0 tw-left-0 tw-w-full"
      >
        <div
          className="tw-flex tw-items-center tw-justify-center tw-h-16 tw-p-3"
          style={{ width: 100 }}
        >
          <img src={colors?.logo_sm} style={{ maxHeight: '90%' }} alt={name} />
        </div>
      </motion.div>
      <div className="tw-flex-1 tw-flex-wrap tw-flex tw-pt-20">
        <motion.div
          initial={{ opacity: 0, x: 10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ transition: 1.2 }}
          className="tw-w-full sm:tw-w-6/12 tw-pr-10"
        >
          <Paragraphs
            italic
            className="tw-text-right tw-text-sm tw-text-4xl "
            weight="bold"
          >
            Iniciar sesión
          </Paragraphs>
          <div className="tw-bg-primary tw-h-1" />
        </motion.div>
        <form
          id="xarxa-login"
          onSubmit={handleSubmit(onSubmit)}
          className="tw-w-full sm:tw-w-6/12 tw-px-20"
        >
          <div

            className=""
          >
            {forms.login.map((i) => {
              return (
                <TextInput
                  type={i.type}
                  key={i.id}
                  label={i.label}
                  register={register(i.name, {
                    required: {
                      value: true,
                      message: 'Campo requerido'
                    }
                  })}
                  error={errors[i.name]?.message}
                />
              )
            })}

            <div className="tw-flex tw-justify-center sm:tw-justify-end tw-py-10">
              <Button
                type="submit"
                label="Acceder"
                uppercase
                onClick={handleSubmit(onSubmit)}
                iconRight={<Icons.IcArrow />}
              />
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  )
}

Login.propTypes = {
  colors: PropTypes.object,
  onSubmit: PropTypes.func,
  name: PropTypes.string
}

export default Login
