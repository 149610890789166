import React from 'react';
import PropTypes from 'prop-types';
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Folder from './components/Foder/Folder';

const Favorites = ({ items, onMoveFav }) => {
	return (
		<div className='Favorites'>
			<Paragraphs weight='bold' className='tw-text-white tw-px-3 tw-py-4'>
				Favoritos
			</Paragraphs>
			<DndProvider backend={HTML5Backend}>
				<div>
					{items.map((i, idx) => (
						<Folder
							onMoveFav={onMoveFav}
							visible={idx > 0}
							key={i.id}
							id={i.id}
							descripcion={i.descripcion}
							items={i.favoritos}
						/>
					))}
				</div>
			</DndProvider>
		</div>
	);
};

Favorites.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object),
	onMoveFav: PropTypes.func,
};
Favorites.defaultProps = {
	items: [],
};

export default Favorites;

