export const SIDEBAR_SMALL = 50
export const SIDEBAR_CONTENT = 250
export const HEIGHT_ICON = 50
export const SIDEBAR_SOFTWARES = 300

export const ITEMS = [

  {
    id: 1600,
    nombre: 'Home',
    tipo: 'MODULO',
    componente: null,
    accion: null,
    imagen: 'https://vapor-eu-west-3-assets-1633792679.s3.eu-west-3.amazonaws.com/icons/ic_inicio.svg',
    padre_id: 1000,
    software_id: 2,
    demo: 0,
    orden: 1,
    visible: 0,
    deleted_at: null,
    created_at: '2021-10-11T14:23:50.000000Z',
    updated_at: '2021-10-11T14:23:50.000000Z',
    modulo_id: null,
    children: [
      {
        id: 1601,
        nombre: 'Home',
        tipo: 'ACCIÓN & LINK',
        componente: 'bi_iframe',
        accion: 'bi_iframe_home_v2',
        imagen: '',
        padre_id: 1600,
        software_id: 2,
        demo: 0,
        orden: 1,
        visible: 0,
        deleted_at: null,
        created_at: '2021-10-11T14:23:50.000000Z',
        updated_at: '2021-10-11T14:23:50.000000Z',
        modulo_id: 1600,
        children: [],
        padre: {
          id: 1600,
          nombre: 'Home',
          tipo: 'MODULO',
          componente: null,
          accion: null,
          imagen: 'https://vapor-eu-west-3-assets-1633792679.s3.eu-west-3.amazonaws.com/icons/ic_inicio.svg',
          padre_id: 1000,
          software_id: 2,
          demo: 0,
          orden: 1,
          visible: 0,
          deleted_at: null,
          created_at: '2021-10-11T14:23:50.000000Z',
          updated_at: '2021-10-11T14:23:50.000000Z',
          modulo_id: null,
          padre: {
            id: 1000,
            nombre: 'Business Intelligence',
            tipo: 'SOFTWARE',
            componente: null,
            accion: null,
            imagen: '',
            padre_id: null,
            software_id: null,
            demo: 0,
            orden: null,
            visible: 1,
            deleted_at: null,
            created_at: null,
            updated_at: null,
            modulo_id: null,
            padre: null
          }
        }
      }
    ]
  },
  {
    id: 1604,
    nombre: 'Mi farmacia',
    tipo: 'MODULO',
    componente: null,
    accion: null,
    imagen: 'https://vapor-eu-west-3-assets-1633792679.s3.eu-west-3.amazonaws.com/icons/ic_mi_farmacia.svg',
    padre_id: 1000,
    software_id: 2,
    demo: 0,
    orden: 2,
    visible: 1,
    deleted_at: null,
    created_at: '2021-10-11T14:23:50.000000Z',
    updated_at: '2021-10-11T14:23:50.000000Z',
    modulo_id: null,
    children: [
      {
        id: 1605,
        nombre: 'Ventas',
        tipo: 'ACCIÓN & LINK',
        componente: 'bi_iframe',
        accion: 'bi_iframe_farmacia_ventas_v2',
        imagen: '',
        padre_id: 1604,
        software_id: 2,
        demo: 0,
        orden: 1,
        visible: 1,
        deleted_at: null,
        created_at: '2021-10-11T14:23:50.000000Z',
        updated_at: '2021-10-11T14:23:50.000000Z',
        modulo_id: 1604,
        children: [],
        padre: {
          id: 1604,
          nombre: 'Mi farmacia',
          tipo: 'MODULO',
          componente: null,
          accion: null,
          imagen: 'https://vapor-eu-west-3-assets-1633792679.s3.eu-west-3.amazonaws.com/icons/ic_mi_farmacia.svg',
          padre_id: 1000,
          software_id: 2,
          demo: 0,
          orden: 2,
          visible: 1,
          deleted_at: null,
          created_at: '2021-10-11T14:23:50.000000Z',
          updated_at: '2021-10-11T14:23:50.000000Z',
          modulo_id: null,
          padre: {
            id: 1000,
            nombre: 'Business Intelligence',
            tipo: 'SOFTWARE',
            componente: null,
            accion: null,
            imagen: '',
            padre_id: null,
            software_id: null,
            demo: 0,
            orden: null,
            visible: 1,
            deleted_at: null,
            created_at: null,
            updated_at: null,
            modulo_id: null,
            padre: null
          }
        }
      },
      {
        id: 1606,
        nombre: 'Compras',
        tipo: 'ACCIÓN & LINK',
        componente: 'bi_iframe',
        accion: 'bi_iframe_farmacia_compras_v2',
        imagen: '',
        padre_id: 1604,
        software_id: 2,
        demo: 0,
        orden: 2,
        visible: 1,
        deleted_at: null,
        created_at: '2021-10-11T14:23:50.000000Z',
        updated_at: '2021-10-11T14:23:50.000000Z',
        modulo_id: 1604,
        children: [],
        padre: {
          id: 1604,
          nombre: 'Mi farmacia',
          tipo: 'MODULO',
          componente: null,
          accion: null,
          imagen: 'https://vapor-eu-west-3-assets-1633792679.s3.eu-west-3.amazonaws.com/icons/ic_mi_farmacia.svg',
          padre_id: 1000,
          software_id: 2,
          demo: 0,
          orden: 2,
          visible: 1,
          deleted_at: null,
          created_at: '2021-10-11T14:23:50.000000Z',
          updated_at: '2021-10-11T14:23:50.000000Z',
          modulo_id: null,
          padre: {
            id: 1000,
            nombre: 'Business Intelligence',
            tipo: 'SOFTWARE',
            componente: null,
            accion: null,
            imagen: '',
            padre_id: null,
            software_id: null,
            demo: 0,
            orden: null,
            visible: 1,
            deleted_at: null,
            created_at: null,
            updated_at: null,
            modulo_id: null,
            padre: null
          }
        }
      },
      {
        id: 1607,
        nombre: 'Equipo',
        tipo: 'ACCIÓN & LINK',
        componente: 'bi_iframe',
        accion: 'bi_iframe_farmacia_equipo_v2',
        imagen: '',
        padre_id: 1604,
        software_id: 2,
        demo: 0,
        orden: 3,
        visible: 1,
        deleted_at: null,
        created_at: '2021-10-11T14:23:50.000000Z',
        updated_at: '2021-10-11T14:23:50.000000Z',
        modulo_id: 1604,
        children: [],
        padre: {
          id: 1604,
          nombre: 'Mi farmacia',
          tipo: 'MODULO',
          componente: null,
          accion: null,
          imagen: 'https://vapor-eu-west-3-assets-1633792679.s3.eu-west-3.amazonaws.com/icons/ic_mi_farmacia.svg',
          padre_id: 1000,
          software_id: 2,
          demo: 0,
          orden: 2,
          visible: 1,
          deleted_at: null,
          created_at: '2021-10-11T14:23:50.000000Z',
          updated_at: '2021-10-11T14:23:50.000000Z',
          modulo_id: null,
          padre: {
            id: 1000,
            nombre: 'Business Intelligence',
            tipo: 'SOFTWARE',
            componente: null,
            accion: null,
            imagen: '',
            padre_id: null,
            software_id: null,
            demo: 0,
            orden: null,
            visible: 1,
            deleted_at: null,
            created_at: null,
            updated_at: null,
            modulo_id: null,
            padre: null
          }
        }
      },
      {
        id: 1608,
        nombre: 'Mis clientes',
        tipo: 'ACCIÓN & LINK',
        componente: 'bi_iframe',
        accion: 'bi_iframe_farmacia_clientes_v2',
        imagen: '',
        padre_id: 1604,
        software_id: 2,
        demo: 0,
        orden: 4,
        visible: 1,
        deleted_at: null,
        created_at: '2021-10-11T14:23:50.000000Z',
        updated_at: '2021-10-11T14:23:50.000000Z',
        modulo_id: 1604,
        children: [],
        padre: {
          id: 1604,
          nombre: 'Mi farmacia',
          tipo: 'MODULO',
          componente: null,
          accion: null,
          imagen: 'https://vapor-eu-west-3-assets-1633792679.s3.eu-west-3.amazonaws.com/icons/ic_mi_farmacia.svg',
          padre_id: 1000,
          software_id: 2,
          demo: 0,
          orden: 2,
          visible: 1,
          deleted_at: null,
          created_at: '2021-10-11T14:23:50.000000Z',
          updated_at: '2021-10-11T14:23:50.000000Z',
          modulo_id: null,
          padre: {
            id: 1000,
            nombre: 'Business Intelligence',
            tipo: 'SOFTWARE',
            componente: null,
            accion: null,
            imagen: '',
            padre_id: null,
            software_id: null,
            demo: 0,
            orden: null,
            visible: 1,
            deleted_at: null,
            created_at: null,
            updated_at: null,
            modulo_id: null,
            padre: null
          }
        }
      }
    ]
  },
  {
    id: 1609,
    nombre: 'Mercado',
    tipo: 'MODULO',
    componente: null,
    accion: null,
    imagen: 'https://vapor-eu-west-3-assets-1633792679.s3.eu-west-3.amazonaws.com/icons/ic_mercado.svg',
    padre_id: 1000,
    software_id: 2,
    demo: 0,
    orden: 3,
    visible: 1,
    deleted_at: null,
    created_at: '2021-10-11T14:23:50.000000Z',
    updated_at: '2021-10-11T14:23:50.000000Z',
    modulo_id: null,
    children: [
      {
        id: 1610,
        nombre: 'Ventas',
        tipo: 'ACCIÓN & LINK',
        componente: 'bi_iframe',
        accion: 'bi_iframe_mercado_ventas_v2',
        imagen: '',
        padre_id: 1609,
        software_id: 2,
        demo: 0,
        orden: 1,
        visible: 1,
        deleted_at: null,
        created_at: '2021-10-11T14:23:50.000000Z',
        updated_at: '2021-10-11T14:23:50.000000Z',
        modulo_id: 1609,
        children: [],
        padre: {
          id: 1609,
          nombre: 'Mercado',
          tipo: 'MODULO',
          componente: null,
          accion: null,
          imagen: 'https://vapor-eu-west-3-assets-1633792679.s3.eu-west-3.amazonaws.com/icons/ic_mercado.svg',
          padre_id: 1000,
          software_id: 2,
          demo: 0,
          orden: 3,
          visible: 1,
          deleted_at: null,
          created_at: '2021-10-11T14:23:50.000000Z',
          updated_at: '2021-10-11T14:23:50.000000Z',
          modulo_id: null,
          padre: {
            id: 1000,
            nombre: 'Business Intelligence',
            tipo: 'SOFTWARE',
            componente: null,
            accion: null,
            imagen: '',
            padre_id: null,
            software_id: null,
            demo: 0,
            orden: null,
            visible: 1,
            deleted_at: null,
            created_at: null,
            updated_at: null,
            modulo_id: null,
            padre: null
          }
        }
      },
      {
        id: 1611,
        nombre: 'Pricing',
        tipo: 'ACCIÓN & LINK',
        componente: 'bi_iframe',
        accion: 'bi_iframe_mercado_pricing_v2',
        imagen: '',
        padre_id: 1609,
        software_id: 2,
        demo: 0,
        orden: 2,
        visible: 1,
        deleted_at: null,
        created_at: '2021-10-11T14:23:50.000000Z',
        updated_at: '2021-10-11T14:23:50.000000Z',
        modulo_id: 1609,
        children: [],
        padre: {
          id: 1609,
          nombre: 'Mercado',
          tipo: 'MODULO',
          componente: null,
          accion: null,
          imagen: 'https://vapor-eu-west-3-assets-1633792679.s3.eu-west-3.amazonaws.com/icons/ic_mercado.svg',
          padre_id: 1000,
          software_id: 2,
          demo: 0,
          orden: 3,
          visible: 1,
          deleted_at: null,
          created_at: '2021-10-11T14:23:50.000000Z',
          updated_at: '2021-10-11T14:23:50.000000Z',
          modulo_id: null,
          padre: {
            id: 1000,
            nombre: 'Business Intelligence',
            tipo: 'SOFTWARE',
            componente: null,
            accion: null,
            imagen: '',
            padre_id: null,
            software_id: null,
            demo: 0,
            orden: null,
            visible: 1,
            deleted_at: null,
            created_at: null,
            updated_at: null,
            modulo_id: null,
            padre: null
          }
        }
      }
    ]
  },
  {
    id: 1612,
    nombre: 'Negociación',
    tipo: 'MODULO',
    componente: null,
    accion: null,
    imagen: 'https://vapor-eu-west-3-assets-1633792679.s3.eu-west-3.amazonaws.com/icons/ic_clientes.svg',
    padre_id: 1000,
    software_id: 2,
    demo: 0,
    orden: 4,
    visible: 1,
    deleted_at: null,
    created_at: '2021-10-11T14:23:50.000000Z',
    updated_at: '2021-10-11T14:23:50.000000Z',
    modulo_id: null,
    children: [
      {
        id: 1613,
        nombre: 'Gestión marca-genérico',
        tipo: 'ACCIÓN & LINK',
        componente: 'bi_iframe',
        accion: 'bi_iframe_negociacion_gestion_marca_generico_v2',
        imagen: '',
        padre_id: 1612,
        software_id: 2,
        demo: 0,
        orden: 1,
        visible: 1,
        deleted_at: null,
        created_at: '2021-10-11T14:23:50.000000Z',
        updated_at: '2021-10-11T14:23:50.000000Z',
        modulo_id: 1612,
        children: [],
        padre: {
          id: 1612,
          nombre: 'Negociación',
          tipo: 'MODULO',
          componente: null,
          accion: null,
          imagen: 'https://vapor-eu-west-3-assets-1633792679.s3.eu-west-3.amazonaws.com/icons/ic_clientes.svg',
          padre_id: 1000,
          software_id: 2,
          demo: 0,
          orden: 4,
          visible: 1,
          deleted_at: null,
          created_at: '2021-10-11T14:23:50.000000Z',
          updated_at: '2021-10-11T14:23:50.000000Z',
          modulo_id: null,
          padre: {
            id: 1000,
            nombre: 'Business Intelligence',
            tipo: 'SOFTWARE',
            componente: null,
            accion: null,
            imagen: '',
            padre_id: null,
            software_id: null,
            demo: 0,
            orden: null,
            visible: 1,
            deleted_at: null,
            created_at: null,
            updated_at: null,
            modulo_id: null,
            padre: null
          }
        }
      },
      {
        id: 1614,
        nombre: 'Club Fedefarma Integral',
        tipo: 'ACCIÓN & LINK',
        componente: 'bi_iframe',
        accion: 'bi_iframe_negociacion_club_fedefarma_integral_v2',
        imagen: '',
        padre_id: 1612,
        software_id: 2,
        demo: 0,
        orden: 2,
        visible: 1,
        deleted_at: null,
        created_at: '2021-10-11T14:23:50.000000Z',
        updated_at: '2021-10-11T14:23:50.000000Z',
        modulo_id: 1612,
        children: [],
        padre: {
          id: 1612,
          nombre: 'Negociación',
          tipo: 'MODULO',
          componente: null,
          accion: null,
          imagen: 'https://vapor-eu-west-3-assets-1633792679.s3.eu-west-3.amazonaws.com/icons/ic_clientes.svg',
          padre_id: 1000,
          software_id: 2,
          demo: 0,
          orden: 4,
          visible: 1,
          deleted_at: null,
          created_at: '2021-10-11T14:23:50.000000Z',
          updated_at: '2021-10-11T14:23:50.000000Z',
          modulo_id: null,
          padre: {
            id: 1000,
            nombre: 'Business Intelligence',
            tipo: 'SOFTWARE',
            componente: null,
            accion: null,
            imagen: '',
            padre_id: null,
            software_id: null,
            demo: 0,
            orden: null,
            visible: 1,
            deleted_at: null,
            created_at: null,
            updated_at: null,
            modulo_id: null,
            padre: null
          }
        }
      },
      {
        id: 1615,
        nombre: 'Stock',
        tipo: 'ACCIÓN & LINK',
        componente: 'bi_iframe',
        accion: 'bi_iframe_negociacion_stock_v2',
        imagen: '',
        padre_id: 1612,
        software_id: 2,
        demo: 0,
        orden: 3,
        visible: 1,
        deleted_at: null,
        created_at: '2021-10-11T14:23:50.000000Z',
        updated_at: '2021-10-11T14:23:50.000000Z',
        modulo_id: 1612,
        children: [],
        padre: {
          id: 1612,
          nombre: 'Negociación',
          tipo: 'MODULO',
          componente: null,
          accion: null,
          imagen: 'https://vapor-eu-west-3-assets-1633792679.s3.eu-west-3.amazonaws.com/icons/ic_clientes.svg',
          padre_id: 1000,
          software_id: 2,
          demo: 0,
          orden: 4,
          visible: 1,
          deleted_at: null,
          created_at: '2021-10-11T14:23:50.000000Z',
          updated_at: '2021-10-11T14:23:50.000000Z',
          modulo_id: null,
          padre: {
            id: 1000,
            nombre: 'Business Intelligence',
            tipo: 'SOFTWARE',
            componente: null,
            accion: null,
            imagen: '',
            padre_id: null,
            software_id: null,
            demo: 0,
            orden: null,
            visible: 1,
            deleted_at: null,
            created_at: null,
            updated_at: null,
            modulo_id: null,
            padre: null
          }
        }
      },
      {
        id: 1616,
        nombre: 'Margen',
        tipo: 'ACCIÓN & LINK',
        componente: 'bi_iframe',
        accion: 'bi_iframe_negociacion_margen_v2',
        imagen: '',
        padre_id: 1612,
        software_id: 2,
        demo: 0,
        orden: 4,
        visible: 1,
        deleted_at: null,
        created_at: '2021-10-11T14:23:50.000000Z',
        updated_at: '2021-10-11T14:23:50.000000Z',
        modulo_id: 1612,
        children: [],
        padre: {
          id: 1612,
          nombre: 'Negociación',
          tipo: 'MODULO',
          componente: null,
          accion: null,
          imagen: 'https://vapor-eu-west-3-assets-1633792679.s3.eu-west-3.amazonaws.com/icons/ic_clientes.svg',
          padre_id: 1000,
          software_id: 2,
          demo: 0,
          orden: 4,
          visible: 1,
          deleted_at: null,
          created_at: '2021-10-11T14:23:50.000000Z',
          updated_at: '2021-10-11T14:23:50.000000Z',
          modulo_id: null,
          padre: {
            id: 1000,
            nombre: 'Business Intelligence',
            tipo: 'SOFTWARE',
            componente: null,
            accion: null,
            imagen: '',
            padre_id: null,
            software_id: null,
            demo: 0,
            orden: null,
            visible: 1,
            deleted_at: null,
            created_at: null,
            updated_at: null,
            modulo_id: null,
            padre: null
          }
        }
      }
    ]
  }
]

export const ITEM_CONFIGURATION = {
  activo: true,
  children: [
    {
      children: [],
      componente: null,
      id: '1',
      nombre: 'Configuración',
      software_id: 0
    }
  ],
  demo: false,
  id: '0',
  nombre: 'Perfil Sfera',
  visible: true
}

export const FAVORITES = [
  {
    id: '1',
    descripcion: 'Principal',
    favoritos: [
      {
        id: '1',
        recurso: {
          visible: true,
          id: '201',
          nombre: 'TPV',
          componente: 'ventas_tpv',
          software: {
            id: '100',
            nombre: 'ERP Farmacéutico'
          }
        }
      },
      {
        id: '2',
        recurso: {
          visible: true,
          id: '203',
          nombre: 'TPV',
          componente: 'ventas_tpv',
          software: {
            id: '100',
            nombre: 'ERP Farmacéutico'
          }
        }
      }
    ]
  },
  {
    id: '3',
    descripcion: 'favoritos',
    favoritos: [
      {
        id: '2',
        recurso: {
          visible: true,
          id: '2',
          nombre: 'Interfaz de Empresa',
          componente: 'config_interfaz',
          software: {
            id: '0',
            nombre: 'Perfil Sfera'
          }
        }
      }
    ]
  }
]
