import React from 'react';
import PropTypes from 'prop-types';
import { LayoutContainer } from './styles';
import useInfoCorporate from '../../hooks/useInfoCorporate';
import CustomHeader from '../../../components/CustomHeader';
import { actionChangeLanguage } from '../../redux/actions/user';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../../components/Sidebar';

const Layout = ({ children }) => {
	const { apps } = useInfoCorporate();

	const dispatch = useDispatch();
	/**
	 * open software on new chrome tab
	 *
	 *
	 * @author   ehernandez
	 * @param   {Object} app
	 * @param   {Object}  app.url
	 * @param   {Object}  app.comprado
	 */

	const openSotware = (app) => {
		if (app?.comprada) {
			window.open(app.url + '/' + app.token);
		}
	};

	const onChangeLanguage = (lang) => {
		dispatch(actionChangeLanguage(lang));
	};
	const { t } = useTranslation();
	return (
		<LayoutContainer className='layout tw-h-screen  tw-relative tw-flex tw-flex-col tw-bg-gray-50'>
			<div className='tw-absolute tw-w-full tw-top-0'>
				<CustomHeader onChangeLanguage={onChangeLanguage} />
			</div>
			<div className='tw-relative tw-h-full' style={{ paddingLeft: 50 }}>
				<Sidebar
					titleSidebarSoftware={t('menu.Aplicaciones')}
					onSelectSoftware={openSotware}
					position={'absolute'}
					softwares={apps && apps.filter((i) => i.comprada)}
				/>
				<div className='tw-h-full tw-w-full'>{children}</div>
			</div>
		</LayoutContainer>
	);
};

Layout.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export default Layout;
