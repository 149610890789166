import React, { useState, useRef } from 'react'
import { ContainerCustomHeader, ContentHeader, Links, Menu } from './style'
import useInfoCorporate from '../../infrastructure/hooks/useInfoCorporate'
import { useTranslation } from 'react-i18next'
import { links } from './constanst'
import { useUser } from '../../infrastructure/hooks/useUser/useUser'
import useListenerClose from '../../infrastructure/hooks/useListenerClose'
import PropTypes from 'prop-types'
const CustomHeader = ({ onChangeLanguage }) => {
  const { info } = useInfoCorporate()
  const [openLang, setOpenLang] = useState(false)
  const [optionProfile, setOptionProfile] = useState(false)
  const { t, i18n } = useTranslation()
  const [menuMovile, setMenuMovile] = useState(false)
  const { user } = useUser()
  const refScroll = useRef(null)
  const [hoverImage, sethoverImage] = useState(false)

  const onCloseMenu = () => {
    setMenuMovile(false)
    setOpenLang(false)
    setOptionProfile(false)
  }

  const { ref } = useListenerClose(onCloseMenu)

  const changeLang = (lang) => {
    i18n.changeLanguage(lang)
    setOpenLang(false)
    onChangeLanguage(lang)
  }

  return (
    <ContainerCustomHeader className="tw-w-full">
      <ContentHeader className="tw-w-full tw-flex tw-justify-between tw-items-center tw-h-full tw-px-1 lg:tw-px-16">
        <div>
          <a
            style={{ maxHeight: 50, maxWidth: 50, minWidth: 50, minHeight: 50 }}
            className="tw-flex tw-items-center tw-flex-1"
            href="https://particulars.xarxafarma.com/pharmacy/home"
          >
            <img alt="" src="https://apps.xarxafarma.com/img/logos/xf.png" />
          </a>
        </div>
        <div
          className="tw-justify-center tw-hidden lg:tw-flex tw-h-full"
          style={{ overflow: 'hidden' }}
        >
          <Links
            onClick={(e) => console.log((refScroll.current.scrollLeft = 0))}
            className="tw-h-full tw-flex chevron tw-items-center tw-uppercase"
            rel="noreferrer"
          >
            <span className="material-icons ">chevron_left</span>
          </Links>
          <div
            className="tw-w-full tw-flex"
            ref={refScroll}
            style={{
              contain: 'content',
              display: 'flex',
              flex: '1 1 auto',
              overflow: 'hidden'
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: '1 0 auto',
                position: 'relative',
                transition: '.3s cubic-bezier(.25,.8,.5,1)',
                whiteSpace: 'nowrap'
              }}
            >
              {links.map((i) => (
                <Links
                  key={i.id}
                  className="tw-h-full tw-flex tw-items-center tw-uppercase"
                  href={i.link}
                  rel="noreferrer"
                >
                  {/* <a href={i.link}> */}
                  <span style={{ fontWeight: 700 }}>{t(`menu.${i.name}`)}</span>
                  {/* </a> */}
                </Links>
              ))}
            </div>
          </div>
          <Links
            onClick={(e) => console.log((refScroll.current.scrollLeft = 500))}
            className="tw-h-full tw-flex chevron tw-items-center tw-uppercase"
            rel="noreferrer"
          >
            <span className="material-icons ">chevron_right</span>
          </Links>
        </div>
        <div className="tw-flex tw-items-center tw-h-full">
        <a
            href="https://apps2.xarxafarma.com/"
            onMouseEnter={() => sethoverImage(true)}
            onMouseLeave={() => sethoverImage(false)}
            type="button"
            className="my-auto tw-mx-4 hidden-sm-and-down tw-hidden lg:tw-block"
          >
            {hoverImage
              ? (
              <img width={75} height={36} style={{ maxWidth: 'max-content' }}src="https://apps.xarxafarma.com/img/icons/sfera-on.png" />
                )
              : (
              <img width={75} height={36} style={{ maxWidth: 'max-content' }}src="https://apps.xarxafarma.com/img/icons/sfera.png" />
                )}
          </a>
          <hr
            style={{ backgroundColor: 'rgba(0,0,0,.12)', width: 1, height: 90 }}
          />
          <div className="tw-h-full tw-relative">

            <Links
              className="tw-h-full tw-flex tw-items-center tw-flex-col tw-justify-center tw-text-center "
              rel="noreferrer"
            >
              <div
                className="tw-uppercase col-responsive"
                onClick={() => setOptionProfile(true)}
                style={{ letterSpacing: 1.3 }}
              >
                <div className="tw-text-sm" style={{ fontWeight: 600 }}>
                  {info?.nombre}
                </div>
                <div className="tw-text-sm" style={{ fontWeight: 600 }}>
                  {user?.name}
                </div>
              </div>
              {optionProfile && (
                <Menu ref={ref} style={{ minWidth: 160 }}>
                  <Links
                    className="tw-h-full tw-flex tw-items-center tw-text-center"
                    rel="noreferrer"
                  >
                    <a
                      className="item-title tw-py-3 tw-text-black"
                      href="https://apps.xarxafarma.com/guest/pharmacy/profile"
                      style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.87)' }}
                    >
                      {t('menu.Perfil')}
                    </a>
                  </Links>
                  <Links
                    className="tw-h-full tw-flex tw-items-center tw-text-center"
                    rel="noreferrer"
                  >
                    <a
                      href="https://apps.xarxafarma.com/logout"
                      className="item-title tw-py-3 tw-text-black"
                      style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.87)' }}
                    >
                      {t('menu.Cerrar sesión')}
                    </a>
                  </Links>
                </Menu>
              )}
            </Links>
          </div>
          <hr
            style={{ backgroundColor: 'rgba(0,0,0,.12)', width: 1, height: 90 }}
          />

          <div className="tw-h-full tw-relative ">
            <Links
              className="tw-h-full tw-flex tw-items-center tw-text-center"
              rel="noreferrer"
            >
              <strong
                onClick={() => setOpenLang(true)}
                className="tw-text-black tw-uppercase"
                style={{ letterSpacing: 1.2, color: 'rgba(0,0,0,.87);' }}
              >
                {t(`menu.${i18n.language}`)}
              </strong>
              {openLang && (
                <Menu ref={ref}>
                  <Links
                    onClick={() => changeLang('ca')}
                    className="tw-h-full tw-flex tw-items-center tw-text-center"
                    rel="noreferrer"
                  >
                    <div
                      className="item-title tw-py-3 tw-text-black"
                      style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.87)' }}
                    >
                      {t('menu.ca')}
                    </div>
                  </Links>
                  <Links
                    onClick={() => changeLang('es')}
                    className="tw-h-full tw-flex tw-items-center tw-text-center"
                    rel="noreferrer"
                  >
                    <div
                      className="item-title tw-py-3 tw-text-black"
                      style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.87)' }}
                    >
                      {t('menu.es')}
                    </div>
                  </Links>
                </Menu>
              )}
            </Links>
          </div>
          <div className="tw-h-full  tw-flex lg:tw-hidden tw-items-center tw-relative">
            <Links
              className="tw-h-full tw-flex tw-items-center tw-p-1 tw-justify-center"
              style={{ width: 50, height: 50, borderRadius: 1000, padding: 0 }}
            >
              <span
                onClick={() => setMenuMovile(true)}
                className="material-icons"
              >
                menu
              </span>
              {menuMovile && (
                <Menu
                  ref={ref}
                  className="tw-text-center tw-justify-center tw-px-3"
                  style={{ paddingLeft: 15, paddingRight: 15, minWidth: 250 }}
                >
                  {links.map((i) => (
                    <Links
                      key={i.id}
                      onClick={() => changeLang('ca')}
                      className="tw-h-full tw-flex tw-items-center tw-text-center tw-justify-center tw-py-3 tw-uppercase"
                      rel="noreferrer"
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderRadius: 5
                      }}
                    >
                      <a href={i.link}>
                        <span>{t(`menu.${i.name}`)}</span>
                      </a>
                    </Links>
                  ))}
                                      <a
                      href="https://apps2.xarxafarma.com/"
                      onMouseEnter={() => sethoverImage(true)}
                      onMouseLeave={() => sethoverImage(false)}
                      type="button"
                      className="my-auto me-4 hidden-sm-and-down"
                    >
                      {hoverImage
                        ? (
                        <img src="https://apps.xarxafarma.com/img/icons/sfera-on.png" />
                          )
                        : (
                        <img src="https://apps.xarxafarma.com/img/icons/sfera.png" />
                          )}
                    </a>
                </Menu>
              )}
            </Links>
          </div>
        </div>
      </ContentHeader>
    </ContainerCustomHeader>
  )
}

CustomHeader.propTypes = {
  onChangeLanguage: PropTypes.func
}
export default CustomHeader
