import styled from 'styled-components'

export const ContainerCustomHeader = styled.div`
    height: 90px;
    background-color: #FAFAFA;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
    /* padding: 0 48px; */
`

export const ContentHeader = styled.div`
    /* padding: 0px 16px; */
`

export const Links = styled.a`
    font-family: Century Gothic,Roboto,sans-serif;
    padding: 0 16px;
    font-size: 14px;
    color: rgba(0,0,0,.55);
    cursor: pointer;
    letter-spacing: .0071428571em!important;
    vertical-align: middle;
    white-space: nowrap;
    
    &:hover{
        background-color: #E3E3E3;
    }

    & .col-responsive{
        @media (max-width: 481px) {
            width: 200px;
        }
    }

    & a{
        vertical-align: middle;
         white-space: nowrap;
    }

    &.chevron{
        display: none;
        @media (max-width: 1520px) {
           display: flex;
        }
    }


`

export const BtnName = styled.div`
    min-width: 64px;
    padding: 0 16px;
    cursor: pointer;
    height: 100%;
    color: ${props => props.theme === 'black' ? '#000' : '#868686'};
    caret-color: #757575!important;
    font-size: .875rem;
    border-radius: 0;
    height: 100%!important;
    max-height: none;
    position: relative;
    &:before{
        background-color: currentColor;
    border-radius: inherit;
    bottom: 0;
    color: inherit;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .2s cubic-bezier(.4,0,.6,1);
    font-family:"Century Gothic", Roboto, sans-serif;

    }

    &:hover::before{
        opacity: .08;
    }
    & span{
        align-items: center;
    color: inherit;
    display: flex;
    flex: 1 0 auto;
    line-height: normal;
    position: relative;
    transition: inherit;
    transition-property: opacity;
    }
`

export const Menu = styled.div`
    position: absolute;
    background-color: white;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
    border-radius: 4px;
    background: #fff;
    color: rgba(0,0,0,.87);
    padding: 8px 0;
    transform-origin: left top;
    overflow-y: auto;
    overflow-x: hidden;
    top: 90px;
    z-index: 99;
    width: 100%;
    right: 0;
    & .item-title{
        font-size: 15px;
    }
`

export const BtnMenu = styled.button`
    height: 48px;
    width:48px;
    color: rgba(0,0,0,.54);
    font-size: .875rem;
    border-radius: 50%;
    & span{
        align-items: center;
        color: inherit;
        display: flex;
        flex: 1 0 auto;
        line-height: normal;
        position: relative;
        transition: inherit;
        transition-property: opacity;
    }
`

export const Hr = styled.hr`
    align-self: stretch;
    border: solid;
    border-width: 0 thin 0 0;
    display: inline-flex;
    height: inherit;
    min-height: 100%;
    max-height: 100%;
    max-width: 0;
    width: 0;
    vertical-align: text-bottom;
    margin: 0 -1px;
`
