import styled from 'styled-components'

export const ContentFav = styled.div`
  width: 50px;
  height: 50px;
`
export const ContainerItemMenu = styled.div`
  position: relative;
  &:hover::before {
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &:hover > .SubMenuItem {
    opacity: 1;
    visibility: visible;
  }
`

export const ContainerSubItemMenu = styled.div`
  position: absolute;
  right: 0px;
  transform: translateX(100%);
  background: white;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  width: 215px;
  opacity: 0;
  visibility: hidden;
`
