import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
// import { useUser } from '../hooks/useUser/useUser'

const AppRoute = ({ component: Component, isAuthProtected, path, Layout }) => {
  const { token } = useSelector((state) => state.userReducer)
  // const { handleUser } = useUser()

  /**
   * Update data user
   * when user change route
   * @author   ehernandez
   */
  useEffect(() => {
    // if (token) {
    //   handleUser()
    // }
  }, [])

  return (

      <Route
        path={path}
        exact={true}
        render={() => {
          if (isAuthProtected && !token) {
            return <Redirect to="/login" />
          }
          if (Layout) {
            return (
              <Layout>
                <Component />
              </Layout>
            )
          }

          return <Component />
        }}
      />
  )
}

AppRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthProtected: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  Layout: PropTypes.func
}

export default AppRoute
