import React from 'react'
import PropTypes from 'prop-types'
import ICloudButton from '../ICloudButton'
import { ImageItemSoftwares } from './styles'
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'

const ItemSoftwares = ({ image, name, description, active, onClick, notifications, btnText, mode }) => {
  return (
    <div
      className={`item-software tw-border tw-border-gray-200 hover:tw-shadow-md tw-h-full  ${
        active ? 'tw-filter tw-grayscale' : ''
      }`}
    >
      <ImageItemSoftwares className="tw-overflow-hidden tw-flex tw-items-center tw-justify-center tw-bg-gray-400" background={image} />

      <div className={`tw-flex tw-flex-${mode} tw-justify-between tw-items-center tw-px-4 tw-py-2`}>
        <div className="tw-flex-1">
          <Paragraphs weight="bold">{name}</Paragraphs>
          <Paragraphs size="sm">{description}</Paragraphs>
        </div>
        <div className="tw-flex tw-items-center">
        {/* {notifications && <div className="tw-px-4 tw-cursor-pointer">
            <Icons.IcBell size={30} />
          </div>} */}
       {btnText && <ICloudButton
            onClick={onClick}
            label={btnText}
            border="black"
            rounded
          />}
        </div>
      </div>
    </div>
  )
}

ItemSoftwares.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  notifications: PropTypes.bool,
  btnText: PropTypes.string,
  mode: PropTypes.oneOf(['col', 'row'])
}

ItemSoftwares.defaultProps = {
  mode: 'row'
}

export default ItemSoftwares
