import React from 'react';
import PropTypes from 'prop-types';
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git';
import ItemMenu from '../ItemMenu';

const Module = ({
	title,
	items,
	onClickItemMenu,
	onClickFav,
	modeTextLink,
}) => {
	return (
		<div className='Module'>
			<Paragraphs weight='bold' className='tw-text-white tw-px-3 tw-py-4'>
				{title}
			</Paragraphs>
			<div className='Module-items'>
				{items.map((i) => (
					<ItemMenu
						key={i.id}
						nombre={i.nombre}
						childrens={i.children}
						id={i.id}
						idFav={i.id_favorito}
						onClickFav={onClickFav}
						onClickItemMenu={() => onClickItemMenu(i)}
						mode={modeTextLink}
					/>
				))}
			</div>
		</div>
	);
};

Module.propTypes = {
	title: PropTypes.string,
	items: PropTypes.array,
	onClickFav: PropTypes.func,
	onClickItemMenu: PropTypes.func,
	modeTextLink: PropTypes.string,
};

Module.defaultProps = {
	items: [],
};

export default Module;

