import React from 'react';
import PropTypes from 'prop-types';
import IcFavoriteOutline from '../../../../../../../Icons/IcFavoriteOutline/IcFavoriteOutline';
import IcFavorite from '../../../../../../../Icons/IcFavorite/IcFavorite';

const BtnFavorite = ({ id, mode }) => {
	return (
		<div className='BtnFavrite tw-relative tw-flex'>
			<div className='tw-relative tw-z-20'>
				<IcFavoriteOutline color={mode === 'black' ? '#eee' : mode} size={12} />
			</div>
			{id && (
				<div className='tw-absolute tw-top-0 tw-left-0 tw-z-10'>
					<IcFavorite size={12} color='white' />
				</div>
			)}
		</div>
	);
};

BtnFavorite.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	mode: PropTypes.oneOf(['black', 'white']),
};

export default BtnFavorite;

