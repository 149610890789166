import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { APPS, INFO_CORPORATE } from '../../constants/api'
import { actionSetApp, actionSetCorporateInfo } from '../../redux/actions/corporate'
import useMutation from '../useMutation'

export const useInfoCorporate = () => {
  const [handleGetSoftwares] = useMutation(APPS)
  const [handleGetInfoCorporate] = useMutation(INFO_CORPORATE)
  const [loading, setLoading] = useState(false)
  const { apps, info } = useSelector(state => state.corporateReducer)

  const dispatch = useDispatch()

  /**
   * Get info organizations
   *
   *
   *
   * @author   ehernandez
   */
  const getInfoCorporate = async () => {
    setLoading(true)
    const { success, data } = await handleGetInfoCorporate({ method: 'get' })
    setLoading(false)
    if (!success) {
      return null
    }

    dispatch(actionSetCorporateInfo(data))
    return {
      success,
      data
    }
  }

  /**
   * Get softwares organizations
   *
   *
   *
   * @author   ehernandez
   */
  const getApps = async () => {
    setLoading(true)
    const { success, data } = await handleGetSoftwares({ method: 'get' })
    setLoading(false)
    if (!success) {
      return null
    }
    dispatch(actionSetApp(data))
    return {
      success,
      data
    }
  }

  useEffect(() => {
    getInfoCorporate()
  }, [])

  return { getInfoCorporate, getApps, loading, apps, info }
}
